// variables
$bezier: cubic-bezier(0.23, 1, 0.32, 1);
$slideTime: 400ms;

// calendar width calculation
$daySize: 39px;
$padding: 2px;
$dayMargin: 2px;
$daysWidth: ($daySize*7 + $dayMargin*14 + $padding*2 + 2);
$calendarWidth: $daysWidth;

$monthNavHeight: 28px !default;
$weekdaysHeight: 28px !default;
$timeHeight: 40px;

// Colors
$calendarBackground: #ffffff !default;
$calendarBorderColor: #e6e6e6 !default;

$inputBackground: $calendarBackground !default;
$inputForeground: rgba(black, 0.9) !default;
$inputHover: #DDDDDD !default;
$inputDisabled: #BBBBBB !default;

$monthForeground: rgba(black, 0.9) !default;
$arrow_hover_color: #f64747 !default;

$monthBackground: transparent !default;

$weekdaysBackground: transparent !default;
$weekdaysForeground: rgba(black, 0.54) !default;

$dayForeground: #393939 !default;
$dayHoverBackground: #e6e6e6 !default;

$todayColor: #959ea9 !default;
$selectedDayBackground: #569FF7 !default;

$invertedBg: invert($calendarBackground);