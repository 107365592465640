// -- Backgrounds
$theme_background: #376EA8 !default;
$theme_conference: #5E2580 !default;
$theme_weekend: #5E2580 !default;
$theme_good: #3B6B24 !default;
$theme_caution: #802525 !default;

// -- Fonts
$theme_highlight: #F2D918 !default;
$theme_font_light: #f5f5f5 !default;
$theme_font_dark: #1c1c1c !default;