.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #376EA8;
  box-shadow: 1px 0 0 #2a3746, -1px 0 0 #2a3746, 0 1px 0 #2a3746, 0 -1px 0 #2a3746, 0 3px 13px rgba(0, 0, 0, 0.08);
}
.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #2a3746;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before, .flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #2a3746;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #376EA8;
}
.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #2a3746;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #376EA8;
}
.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: #f5f5f5;
  fill: #f5f5f5;
  height: 28px;
  line-height: 1;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex: 1;
}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 16px;
  height: 28px;
  padding: 10px;
  z-index: 3;
  color: #f5f5f5;
  fill: #f5f5f5;
}
.flatpickr-months .flatpickr-prev-month.disabled, .flatpickr-months .flatpickr-next-month.disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*!
  /*rtl:begin:ignore*/ /*
*/
  left: 0;
  /*!
  /*rtl:end:ignore*/ /*
*/
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*!
  /*rtl:begin:ignore*/ /*
*/
  right: 0;
  /*!
  /*rtl:end:ignore*/ /*
*/
}
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #f8f8f8;
}
.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(245, 245, 245, 0.15);
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(200, 145, 87, 0.1);
}
.numInputWrapper span:active {
  background: rgba(200, 145, 87, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(245, 245, 245, 0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(245, 245, 245, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(245, 245, 245, 0.5);
}
.numInputWrapper:hover {
  background: rgba(200, 145, 87, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6.16px 0 0 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(200, 145, 87, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #f5f5f5;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #f5f5f5;
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(245, 245, 245, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: #f5f5f5;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307px;
}
.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307px;
  min-width: 307px;
  max-width: 307px;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #2a3746;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #f5f5f5;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange, .flatpickr-day.today.inRange, .flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #7893af;
  border-color: #7893af;
}
.flatpickr-day.today {
  border-color: #f8f8f8;
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #f8f8f8;
  background: #f8f8f8;
  color: #376EA8;
}
.flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #3B6B24;
  box-shadow: none;
  color: #fff;
  border-color: #3B6B24;
}
.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #3B6B24;
}
.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #7893af, 5px 0 0 #7893af;
}
.flatpickr-day.disabled, .flatpickr-day.disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(245, 245, 245, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.disabled, .flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: rgba(245, 245, 245, 0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #3B6B24, 5px 0 0 #3B6B24;
}
.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  display: inline-block;
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #2a3746;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(245, 245, 245, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #f5f5f5;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #f5f5f5;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #f5f5f5;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #f5f5f5;
  font-weight: bold;
  width: 2%;
  user-select: none;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time .flatpickr-am-pm:focus {
  background: #889fb8;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr .input-group-append {
  cursor: pointer;
}

.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year {
  background-color: #f5f5f5;
  color: #1c1c1c;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year:active {
  background-color: #f5f5f5;
  color: #1c1c1c;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year:focus {
  background-color: set-hover-color(#f5f5f5);
  color: #1c1c1c;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year:disabled {
  background-color: #fafafa;
  color: #1c1c1c;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover {
  background: #3B6B24;
  color: #f5f5f5 !important;
  fill: #f5f5f5 !important;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  color: #f5f5f5 !important;
  fill: #f5f5f5 !important;
}
.flatpickr-calendar .flatpickr-day.prevMonthDay, .flatpickr-calendar .flatpickr-day.nextMonthDay {
  background: #7893af;
  color: #f5f5f5 !important;
}
.flatpickr-calendar .flatpickr-disabled {
  opacity: 0.35;
  cursor: not-allowed !important;
  color: #f5f5f5 !important;
  background: transparent;
}
.flatpickr-calendar .flatpickr-disabled:hover {
  border: 0;
}
.flatpickr-calendar .flatpickr-disabled:hover svg {
  color: #f5f5f5 !important;
  fill: #f5f5f5 !important;
}

.numInputWrapper .arrowUp, .numInputWrapper .arrowUp.disabled, .numInputWrapper .arrowUp[disabled], .numInputWrapper .arrowUp.disabled:hover, .numInputWrapper .arrowUp[disabled]:hover, .numInputWrapper .arrowUp.disabled:focus, .numInputWrapper .arrowUp[disabled]:focus,
.numInputWrapper .arrowDown,
.numInputWrapper .arrowDown.disabled,
.numInputWrapper .arrowDown[disabled],
.numInputWrapper .arrowDown.disabled:hover,
.numInputWrapper .arrowDown[disabled]:hover,
.numInputWrapper .arrowDown.disabled:focus,
.numInputWrapper .arrowDown[disabled]:focus {
  background-color: #376EA8;
  color: #f5f5f5;
}
.numInputWrapper .arrowUp:hover, .numInputWrapper .arrowUp:focus,
.numInputWrapper .arrowDown:hover,
.numInputWrapper .arrowDown:focus {
  background-color: #2c5886;
  color: #f5f5f5;
}

body {
  background: #376EA8;
  color: #f5f5f5;
}
body table caption {
  background: #1f3116;
  color: #f5f5f5;
  border: 2px solid #f5f5f5;
}
body table thead th {
  background: #3B6B24;
  border-color: #f5f5f5;
  color: #f5f5f5;
}
body table thead th.empty-cell {
  background: #376EA8;
}
body table thead td:not(:last-child) {
  border-right: 2px solid #f5f5f5;
}
body table tbody {
  background: rgba(0, 0, 0, 0.75);
  border-color: #f5f5f5;
}
body table tbody th {
  background: #1f3116;
  color: #f5f5f5;
  border-color: #f5f5f5;
}
body table tbody td {
  color: #f5f5f5;
  border: 2px solid #f5f5f5;
}
body table tbody td:not(:last-child) {
  border-right: 2px solid #f5f5f5;
}
body table tbody td:last-child {
  border-right: 2px solid #f5f5f5;
}
body table tbody tr:not(:last-child) td {
  border-bottom: 2px solid #f5f5f5;
}
body table tbody tr:nth-child(2n) {
  background: #5e7d9e;
}
body table tbody tr.totals-row > th {
  border-top: 3px solid #f5f5f5 !important;
}
body table tbody tr.totals-row > td {
  background: #394f2f;
  color: #f5f5f5;
}
body table tbody tr.totals-row > td:not(:first-child) {
  border-right: 2px solid #f5f5f5;
  border-top: 3px solid #f5f5f5 !important;
}
body a {
  color: #cbe2c0;
}
body a:hover {
  color: #66a14a;
}
body label {
  color: #f5f5f5;
}
body .caution-text {
  background: #802525;
  color: #f5f5f5;
}
body .hint-text,
body .highlight-text {
  color: #F2D918;
}
body .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year,
body input[type=text],
body input[type=date],
body input[type=color],
body input[type=number],
body input[type=password],
body textarea,
body select {
  background-color: #f5f5f5;
  color: #1c1c1c;
}
body .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year:active,
body input[type=text]:active,
body input[type=date]:active,
body input[type=color]:active,
body input[type=number]:active,
body input[type=password]:active,
body textarea:active,
body select:active {
  background-color: #f5f5f5;
  color: #1c1c1c;
}
body .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year:focus,
body input[type=text]:focus,
body input[type=date]:focus,
body input[type=color]:focus,
body input[type=number]:focus,
body input[type=password]:focus,
body textarea:focus,
body select:focus {
  background-color: #dddddd;
}
body .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year:hover,
body input[type=text]:hover,
body input[type=date]:hover,
body input[type=color]:hover,
body input[type=number]:hover,
body input[type=password]:hover,
body textarea:hover,
body select:hover {
  background-color: #dddddd;
}
body .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year:disabled,
body input[type=text]:disabled,
body input[type=date]:disabled,
body input[type=color]:disabled,
body input[type=number]:disabled,
body input[type=password]:disabled,
body textarea:disabled,
body select:disabled {
  background-color: #dddddd !important;
}
body .button,
body .button.theme_light,
body label.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-family: inherit;
  font-size: 0.9rem;
  -webkit-appearance: none;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}
[data-whatinput=mouse] body .button,
[data-whatinput=mouse] body .button.theme_light,
[data-whatinput=mouse] body label.button {
  outline: 0;
}
body .button, body .button.disabled, body .button[disabled], body .button.disabled:hover, body .button[disabled]:hover, body .button.disabled:focus, body .button[disabled]:focus,
body .button.theme_light,
body .button.theme_light.disabled,
body .button.theme_light[disabled],
body .button.theme_light.disabled:hover,
body .button.theme_light[disabled]:hover,
body .button.theme_light.disabled:focus,
body .button.theme_light[disabled]:focus,
body label.button,
body label.button.disabled,
body label.button[disabled],
body label.button.disabled:hover,
body label.button[disabled]:hover,
body label.button.disabled:focus,
body label.button[disabled]:focus {
  background-color: #555555;
  color: #f5f5f5;
}
body .button:hover, body .button:focus,
body .button.theme_light:hover,
body .button.theme_light:focus,
body label.button:hover,
body label.button:focus {
  background-color: #444444;
  color: #f5f5f5;
}
body .button.tiny,
body .button.theme_light.tiny,
body label.button.tiny {
  font-size: 0.6rem;
}
body .button.small,
body .button.theme_light.small,
body label.button.small {
  font-size: 0.75rem;
}
body .button.large,
body .button.theme_light.large,
body label.button.large {
  font-size: 1.25rem;
}
body .button.hollow,
body .button.theme_light.hollow,
body label.button.hollow {
  background: transparent;
}
body .button.hollow, body .button.hollow.disabled, body .button.hollow[disabled], body .button.hollow.disabled:hover, body .button.hollow[disabled]:hover, body .button.hollow.disabled:focus, body .button.hollow[disabled]:focus,
body .button.theme_light.hollow,
body .button.theme_light.hollow.disabled,
body .button.theme_light.hollow[disabled],
body .button.theme_light.hollow.disabled:hover,
body .button.theme_light.hollow[disabled]:hover,
body .button.theme_light.hollow.disabled:focus,
body .button.theme_light.hollow[disabled]:focus,
body label.button.hollow,
body label.button.hollow.disabled,
body label.button.hollow[disabled],
body label.button.hollow.disabled:hover,
body label.button.hollow[disabled]:hover,
body label.button.hollow.disabled:focus,
body label.button.hollow[disabled]:focus {
  border: 1px solid #555555;
  color: #555555;
}
body .button.hollow:hover, body .button.hollow:focus,
body .button.theme_light.hollow:hover,
body .button.theme_light.hollow:focus,
body label.button.hollow:hover,
body label.button.hollow:focus {
  border-color: #2a2a2a;
  color: #2a2a2a;
}
body .button.hollow:hover,
body .button.theme_light.hollow:hover,
body label.button.hollow:hover {
  background: rgba(85, 85, 85, 0.25);
}
body .button.theme_background, body .button.theme_background.disabled, body .button.theme_background[disabled], body .button.theme_background.disabled:hover, body .button.theme_background[disabled]:hover, body .button.theme_background.disabled:focus, body .button.theme_background[disabled]:focus,
body .button.theme_light.theme_background,
body .button.theme_light.theme_background.disabled,
body .button.theme_light.theme_background[disabled],
body .button.theme_light.theme_background.disabled:hover,
body .button.theme_light.theme_background[disabled]:hover,
body .button.theme_light.theme_background.disabled:focus,
body .button.theme_light.theme_background[disabled]:focus,
body label.button.theme_background,
body label.button.theme_background.disabled,
body label.button.theme_background[disabled],
body label.button.theme_background.disabled:hover,
body label.button.theme_background[disabled]:hover,
body label.button.theme_background.disabled:focus,
body label.button.theme_background[disabled]:focus {
  background-color: #376EA8;
  color: #f5f5f5;
}
body .button.theme_background:hover, body .button.theme_background:focus,
body .button.theme_light.theme_background:hover,
body .button.theme_light.theme_background:focus,
body label.button.theme_background:hover,
body label.button.theme_background:focus {
  background-color: #2c5886;
  color: #f5f5f5;
}
body .button.theme_medium, body .button.theme_medium.disabled, body .button.theme_medium[disabled], body .button.theme_medium.disabled:hover, body .button.theme_medium[disabled]:hover, body .button.theme_medium.disabled:focus, body .button.theme_medium[disabled]:focus,
body .button.theme_light.theme_medium,
body .button.theme_light.theme_medium.disabled,
body .button.theme_light.theme_medium[disabled],
body .button.theme_light.theme_medium.disabled:hover,
body .button.theme_light.theme_medium[disabled]:hover,
body .button.theme_light.theme_medium.disabled:focus,
body .button.theme_light.theme_medium[disabled]:focus,
body label.button.theme_medium,
body label.button.theme_medium.disabled,
body label.button.theme_medium[disabled],
body label.button.theme_medium.disabled:hover,
body label.button.theme_medium[disabled]:hover,
body label.button.theme_medium.disabled:focus,
body label.button.theme_medium[disabled]:focus {
  background-color: #3e5369;
  color: #f5f5f5;
}
body .button.theme_medium:hover, body .button.theme_medium:focus,
body .button.theme_light.theme_medium:hover,
body .button.theme_light.theme_medium:focus,
body label.button.theme_medium:hover,
body label.button.theme_medium:focus {
  background-color: #324254;
  color: #f5f5f5;
}
body .button.alert, body .button.alert.disabled, body .button.alert[disabled], body .button.alert.disabled:hover, body .button.alert[disabled]:hover, body .button.alert.disabled:focus, body .button.alert[disabled]:focus,
body .button.theme_light.alert,
body .button.theme_light.alert.disabled,
body .button.theme_light.alert[disabled],
body .button.theme_light.alert.disabled:hover,
body .button.theme_light.alert[disabled]:hover,
body .button.theme_light.alert.disabled:focus,
body .button.theme_light.alert[disabled]:focus,
body label.button.alert,
body label.button.alert.disabled,
body label.button.alert[disabled],
body label.button.alert.disabled:hover,
body label.button.alert[disabled]:hover,
body label.button.alert.disabled:focus,
body label.button.alert[disabled]:focus {
  background-color: #802525;
  color: #f5f5f5;
}
body .button.alert:hover, body .button.alert:focus,
body .button.theme_light.alert:hover,
body .button.theme_light.alert:focus,
body label.button.alert:hover,
body label.button.alert:focus {
  background-color: #661e1e;
  color: #f5f5f5;
}
body .button.theme_good, body .button.theme_good.disabled, body .button.theme_good[disabled], body .button.theme_good.disabled:hover, body .button.theme_good[disabled]:hover, body .button.theme_good.disabled:focus, body .button.theme_good[disabled]:focus,
body .button.theme_light.theme_good,
body .button.theme_light.theme_good.disabled,
body .button.theme_light.theme_good[disabled],
body .button.theme_light.theme_good.disabled:hover,
body .button.theme_light.theme_good[disabled]:hover,
body .button.theme_light.theme_good.disabled:focus,
body .button.theme_light.theme_good[disabled]:focus,
body label.button.theme_good,
body label.button.theme_good.disabled,
body label.button.theme_good[disabled],
body label.button.theme_good.disabled:hover,
body label.button.theme_good[disabled]:hover,
body label.button.theme_good.disabled:focus,
body label.button.theme_good[disabled]:focus {
  background-color: #3B6B24;
  color: #f5f5f5;
}
body .button.theme_good:hover, body .button.theme_good:focus,
body .button.theme_light.theme_good:hover,
body .button.theme_light.theme_good:focus,
body label.button.theme_good:hover,
body label.button.theme_good:focus {
  background-color: #2f561d;
  color: #f5f5f5;
}
body .input-group-label {
  background-color: #394f2f;
  color: #f5f5f5;
}
body .checkbox-switch-container > span.help-text {
  background: rgba(0, 0, 0, 0.75);
  border-color: #3B6B24;
}
body .reveal {
  background: rgba(55, 110, 168, 0.9);
}
body .reveal h2 {
  color: #f5f5f5;
  background-color: #394f2f;
}
body .reveal button.close-button {
  color: #f5f5f5;
}
body .reveal button.close-button:hover {
  color: #f8f8f8;
}
body .reveal > form {
  background: #376EA8;
}
body .reveal .accordion {
  background: #2a3746;
}
body .reveal .accordion .accordion-item .accordion-title {
  color: #f5f5f5;
}
body .reveal .accordion .accordion-item .accordion-title .sub-text {
  color: #f8f8f8;
}
body .reveal .accordion .accordion-item .accordion-title:hover {
  color: #f5f5f5;
  background: #376EA8;
}
body .reveal .accordion .accordion-item .accordion-title:hover .sub-text {
  color: #f5f5f5;
}
body .reveal .accordion .accordion-item .accordion-content {
  background: #3e5369;
}
body .reveal .accordion .accordion-item .accordion-content .label-cell label {
  color: #f5f5f5;
}
body .reveal .accordion .accordion-item.is-active .accordion-title {
  color: #f5f5f5;
  background: #7893af;
}
body .reveal .accordion .accordion-item.is-active .accordion-title .sub-text {
  color: #f8f8f8;
}
body .badge.primary {
  background: #1779ba;
  color: #f5f5f5;
}
body .badge.secondary {
  background: #767676;
  color: #f5f5f5;
}
body .badge.alert {
  background: #fd5d65;
  color: #1c1c1c;
}
body .badge.warning {
  background: #ff8f00;
  color: #1c1c1c;
}
body .badge.highlight {
  background: #ffdc55;
  color: #1c1c1c;
}
body .badge.success {
  background: #9ac153;
  color: #1c1c1c;
}
body .badge.info {
  background: #55BBFF;
  color: #1c1c1c;
}
body .badge.misc {
  background: #9674f7;
  color: #1c1c1c;
}
body .badge.theme_background {
  background: #376EA8;
  color: #f5f5f5;
}
body .badge.theme_light {
  background: #f5f5f5;
  color: #1c1c1c;
}
body .badge.theme_medium {
  background: #3e5369;
  color: #f5f5f5;
}
body .badge.theme_dark {
  background: #1c1c1c;
  color: #f5f5f5;
}
body .badge.theme_conference {
  background: #5E2580;
  color: #f5f5f5;
}
body .badge.theme_weekend {
  background: #5E2580;
  color: #f5f5f5;
}
body .badge.theme_good {
  background: #3B6B24;
  color: #f5f5f5;
}
body .badge.theme_caution {
  background: #802525;
  color: #f5f5f5;
}
body .label.primary {
  background: #1779ba;
  color: #f5f5f5;
}
body .label.secondary {
  background: #767676;
  color: #f5f5f5;
}
body .label.alert {
  background: #fd5d65;
  color: #1c1c1c;
}
body .label.warning {
  background: #ff8f00;
  color: #1c1c1c;
}
body .label.highlight {
  background: #ffdc55;
  color: #1c1c1c;
}
body .label.success {
  background: #9ac153;
  color: #1c1c1c;
}
body .label.info {
  background: #55BBFF;
  color: #1c1c1c;
}
body .label.misc {
  background: #9674f7;
  color: #1c1c1c;
}
body .label.theme_background {
  background: #376EA8;
  color: #f5f5f5;
}
body .label.theme_light {
  background: #f5f5f5;
  color: #1c1c1c;
}
body .label.theme_medium {
  background: #3e5369;
  color: #f5f5f5;
}
body .label.theme_dark {
  background: #1c1c1c;
  color: #f5f5f5;
}
body .label.theme_conference {
  background: #5E2580;
  color: #f5f5f5;
}
body .label.theme_weekend {
  background: #5E2580;
  color: #f5f5f5;
}
body .label.theme_good {
  background: #3B6B24;
  color: #f5f5f5;
}
body .label.theme_caution {
  background: #802525;
  color: #f5f5f5;
}
body .callout {
  background-color: #f5f5f5;
  color: #1c1c1c;
}
body .callout label {
  color: #1c1c1c;
}
body .callout a {
  color: #c3d7b9;
}
body .callout a:hover {
  color: #527441;
}
body .debug-panel.theme-good {
  background-color: #b4df9f;
  color: #1c1c1c;
}
body .debug-panel.theme-caution {
  background-color: #e4a1a1;
  color: #1c1c1c;
}
body .color-swatch.theme_bg_gray {
  background: #5e7d9e;
}
body .color-swatch.theme_bg_light {
  background: #7893af;
}
body .color-swatch.theme_bg {
  background: #376EA8;
}
body .color-swatch.theme_bg_medium {
  background: #3e5369;
}
body .color-swatch.theme_bg_dark {
  background: #2a3746;
}
body .color-swatch.theme_good {
  background: #3B6B24;
}
body .color-swatch.theme_caution {
  background: #802525;
}
body .color-swatch.theme_conference {
  background: #5E2580;
}
body .color-swatch.theme_weekend {
  background: #5E2580;
}
body header .payweek-button,
body header .payweek-label {
  color: #f5f5f5;
}
body header .payweek-button:hover,
body header .payweek-label:hover {
  color: #f8f8f8;
}
body header .payweek-button:hover > span,
body header .payweek-label:hover > span {
  color: #f8f8f8;
}
body header .payweek-button:focus,
body header .payweek-label:focus {
  background-color: transparent !important;
}
body header .payweek-button:focus > span,
body header .payweek-label:focus > span {
  color: #f5f5f5;
}
body header .payweek-button > span,
body header .payweek-label > span {
  color: #f5f5f5;
}
body header span.payweek-label:hover {
  color: #f5f5f5;
}
body header .header-nav {
  background: #3B6B24;
}
body section.payroll-content hr {
  border-color: #f5f5f5;
}
body section.payroll-content h2 > small {
  color: #f8f8f8;
}
body section.payroll-content .payWeek-table thead th,
body section.payroll-content .payWeek-table tbody td .weekHeader {
  background: #376EA8;
  color: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td {
  border-color: #f5f5f5;
  color: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.weekend {
  background: #5E2580;
  color: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.weekend.today .dateDetails .dateNum {
  color: #5E2580;
  background: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.filled {
  background: #3B6B24;
  color: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.filled.today .dateDetails .dateNum {
  color: #3B6B24;
  background: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.prefilled {
  background: #1f3116;
  color: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.prefilled.today .dateDetails .dateNum {
  color: #1f3116;
  background: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.pending {
  background: #802525;
  color: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.pending.today .dateDetails .dateNum {
  color: #802525;
  background: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.conference {
  background: #5E2580;
  color: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.conference.today .dateDetails .dateNum {
  color: #5E2580;
  background: #f5f5f5;
}
body section.payroll-content .payWeek-table tbody td.highlight {
  background: #F2D918;
  color: #1c1c1c;
}
body section.payroll-content .payWeek-table tbody td.highlight.today .dateDetails .dateNum {
  color: #F2D918;
  background: #1c1c1c;
}
body section.payroll-content .payWeek-table.summary thead tr th.highlight {
  background: #F2D918;
  color: #1c1c1c;
}
body section.payroll-content .weekSummary .weekTotals,
body section.payroll-content .timeoffSummary .weekTotals {
  border-color: #f5f5f5;
}
body section.payroll-content .weekSummary .weekTotals.weekend,
body section.payroll-content .timeoffSummary .weekTotals.weekend {
  background: #5E2580;
  color: #f5f5f5;
}
body section.payroll-content .weekSummary .weekTotals.weekend hr,
body section.payroll-content .timeoffSummary .weekTotals.weekend hr {
  border-color: #f5f5f5;
}
body section.payroll-content .weekSummary .weekTotals.filled,
body section.payroll-content .timeoffSummary .weekTotals.filled {
  background: #3B6B24;
  color: #f5f5f5;
}
body section.payroll-content .weekSummary .weekTotals.filled hr,
body section.payroll-content .timeoffSummary .weekTotals.filled hr {
  border-color: #f5f5f5;
}
body section.payroll-content .weekSummary .weekTotals.filled dd.pending-line,
body section.payroll-content .timeoffSummary .weekTotals.filled dd.pending-line {
  background: #802525;
  color: #f5f5f5;
}
body section.payroll-content .weekSummary .weekTotals.pending,
body section.payroll-content .timeoffSummary .weekTotals.pending {
  background: #802525;
  color: #f5f5f5;
}
body section.payroll-content .weekSummary .weekTotals.pending hr,
body section.payroll-content .timeoffSummary .weekTotals.pending hr {
  border-color: #f5f5f5;
}
body section.payroll-content .weekSummary .weekTotals.conference,
body section.payroll-content .timeoffSummary .weekTotals.conference {
  background: #5E2580;
  color: #f5f5f5;
}
body section.payroll-content .weekSummary .weekTotals.conference hr,
body section.payroll-content .timeoffSummary .weekTotals.conference hr {
  border-color: #f5f5f5;
}
body section.payroll-content .pto-totals div.pto-personal {
  background: #3B6B24;
  color: #f5f5f5;
}
body section.payroll-content .pto-totals div.pto-personal div.current-requests {
  border-color: #f5f5f5;
  background: rgba(0, 0, 0, 0.75);
}
body section.payroll-content .pto-totals div.pto-personal div.current-requests .checkbox-switch-container {
  border-color: #3B6B24;
}
body section.payroll-content .time-entry,
body section.payroll-content .result-entry,
body section.payroll-content .expense-entry {
  background: #5e7d9e;
  color: #1c1c1c;
}
body section.payroll-content .time-entry span.hint-text,
body section.payroll-content .result-entry span.hint-text,
body section.payroll-content .expense-entry span.hint-text {
  color: #1c1c1c;
}
body section.payroll-content .time-entry .contract-block > .title,
body section.payroll-content .result-entry .contract-block > .title,
body section.payroll-content .expense-entry .contract-block > .title {
  background: #1f3116;
  color: #f5f5f5;
}
body section.payroll-content .time-entry .contract-table tr.table-break,
body section.payroll-content .result-entry .contract-table tr.table-break,
body section.payroll-content .expense-entry .contract-table tr.table-break {
  background: #5e7d9e;
}
body section.payroll-content .time-entry .contract-table tr.table-break > td,
body section.payroll-content .result-entry .contract-table tr.table-break > td,
body section.payroll-content .expense-entry .contract-table tr.table-break > td {
  background: #5e7d9e;
  border-top: 3px solid #f5f5f5;
  border-bottom: 3px solid #f5f5f5;
}
body section.payroll-content .time-entry.offset,
body section.payroll-content .result-entry.offset,
body section.payroll-content .expense-entry.offset {
  background: #394f2f;
  border: 1px solid #2a3746;
  color: #f5f5f5;
}
body section.payroll-content .time-entry.offset span.hint-text,
body section.payroll-content .result-entry.offset span.hint-text,
body section.payroll-content .expense-entry.offset span.hint-text {
  color: #f5f5f5;
}
body section.payroll-content .time-entry.offset .contract-table tr.table-break,
body section.payroll-content .result-entry.offset .contract-table tr.table-break,
body section.payroll-content .expense-entry.offset .contract-table tr.table-break {
  background: #394f2f;
}
body section.payroll-content .time-entry.offset .contract-table tr.table-break > td,
body section.payroll-content .result-entry.offset .contract-table tr.table-break > td,
body section.payroll-content .expense-entry.offset .contract-table tr.table-break > td {
  background: #394f2f;
}
body section.payroll-content .tabs > .tabs-title > a {
  background: #3B6B24;
  color: #f5f5f5;
  border-color: #f5f5f5;
}
body section.payroll-content .tabs > .tabs-title > a:hover {
  background: #394f2f;
  color: #f5f5f5;
}
body section.payroll-content .tabs > .tabs-title.is-active > a {
  background: #3e5369;
  color: #f5f5f5;
  border-bottom: 1px solid #3e5369;
}
body section.payroll-content .tabs > .tabs-title.is-active > a:hover {
  background: #3e5369;
}
body section.payroll-content .tabs-content {
  background: #3e5369;
  color: #f5f5f5;
  border-color: #f5f5f5;
}
body section.payroll-content .tabs-content a {
  color: #cbe2c0;
}
body section.payroll-content .tabs-content a:hover {
  color: #66a14a;
}
body section.payroll-content .tabs-content dl dd {
  border-color: #f5f5f5;
}
body section.payroll-content .tabs-content .accordion > .accordion-item .accordion-title {
  background: #394f2f;
  color: #f5f5f5;
  border: 2px solid #f5f5f5;
}
body section.payroll-content .tabs-content .accordion > .accordion-item .accordion-title:hover {
  background: #66a14a;
  color: #f5f5f5;
}
body section.payroll-content .tabs-content .accordion > .accordion-item .accordion-content {
  color: #f5f5f5;
}
body section.payroll-content .tabs-content .accordion > .accordion-item .accordion-content table th {
  background: #394f2f;
  color: #f5f5f5;
  border-color: #f5f5f5;
}
body section.payroll-content .tabs-content .accordion > .accordion-item.is-active .accordion-title {
  background: #3B6B24;
  color: #f5f5f5;
}
body section.payroll-content .tabs-content .accordion > .accordion-item.is-active .accordion-title:hover {
  background: #3B6B24;
}