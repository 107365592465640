@import 'settings';
@import 'components/callout';
@import 'components/button';
@import 'components/badge';
@import 'components/label';

// Flatpickr
@import '../vendor/flatpickr/flatpickr';
.numInputWrapper {
	.arrowUp,
	.arrowDown {
		@include button-style($theme_background, auto, auto);
	}
}

body {
	background: $theme_background;
	color: $theme_font;
	// Elements
	table {
		caption {
			background: $theme_good_dark;
			color: $good_dark_background_font;
			border: 2px solid $good_dark_background_font;
		}
		thead {
			th {
				background: $theme_good;
				border-color: $theme_table_border;
				color: $good_background_font;
				&.empty-cell {
					background: $theme_background;
				}
			}
			td {
				&:not(:last-child) {
					border-right: 2px solid $theme_table_border;
				}
			}
		}
		tbody {
			background: $theme_background_fade;
			border-color: $good_background_font;
			th {
				background: $theme_good_dark;
				color: $good_dark_background_font;
				border-color: $theme_table_border;
			}
			td {
				color: $theme_font;
				border: 2px solid $theme_table_border;
				&:not(:last-child) {
					border-right: 2px solid $theme_table_border;
				}
				&:last-child {
					border-right: 2px solid $theme_table_border;
				}
			}
			& tr:not(:last-child) {
				td {
					border-bottom: 2px solid $theme_table_border;
				}
			}
			& tr:nth-child(2n) {
				background: $theme_background_gray;
			}
			& tr.totals-row > th {
				border-top: 3px solid $theme_table_border !important;
			}
			& tr.totals-row > td {
				background: $theme_good_faded;
				color: $theme_table_border;
				&:not(:first-child) {
					border-right: 2px solid $theme_table_border;
					border-top: 3px solid $theme_table_border !important;
				}
			}
		}
	}
	a {
		color: $theme_link_color;
		&:hover {
			color: $theme_link_hover;
		}
	}
	label {
		color: $theme_font_light;
	}
	.caution-text {
		background: $theme_caution;
		color: $caution_background_font;
	}
	.hint-text,
	.highlight-text {
		color: $theme_highlight;
	}
	.flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper .cur-year,
	input[type="text"],
	input[type="date"],
	input[type="color"],
	input[type="number"],
	input[type="password"],
	textarea,
	select {
		background-color: $inputBackground;
		color: $inputForeground;
		&:active {
			background-color: $inputBackground;
			color: $inputForeground;
		}
		&:focus {
			background-color: $inputBackgroundFocus;
		}
		&:hover {
			background-color: $inputBackgroundFocus;
		}
		&:disabled {
			background-color: $inputBackgroundFocus !important;
		}
	}
	.button,
	.button.theme_light,
	label.button {
		@include button(false, $schemeBtn, auto, auto);
		@include button-sizes;
		&.hollow {
			@include button-fill-style('hollow', $schemeBtn, auto, auto);
			background: transparent;
			&:hover {
				background: scale-color($schemeBtn, $alpha: -75%);
			}
		}
		&.theme_background {
			@include button-style($theme_background, auto, auto);
		}
		&.theme_medium {
			@include button-style($theme_background_medium, auto, auto);
		}
		&.alert {
			@include button-style($theme_caution, auto, auto);
		}
		&.theme_good {
			@include button-style($theme_good, auto, auto);
		}
	}
	.input-group-label {
		background-color: $theme_good_faded;
		color: $theme_good_faded_font;
	}
	.checkbox-switch-container {
		& > span.help-text {
			background: $theme_background_fade;
			border-color: $theme_good;
		}
	}

	// Misc.
	.reveal {
		background: scale-color($theme_background, $alpha: -10%);
		h2 {
			color: $theme_font;
			background-color: $theme_good_faded;
		}
		button.close-button {
			color: $theme_font;
			&:hover {
				color: $theme_font_hover;
			}
		}
		& > form {
			background: $theme_background;
		}
		.accordion {
			background: $theme_background_dark;
			.accordion-item {
				.accordion-title {
					color: $theme_font;
					.sub-text {
						color: $theme_font_hover;
					}
					&:hover {
						color: $theme_font;
						background: $theme_background;
						.sub-text {
							color: $theme_font;
						}
					}
				}
				.accordion-content {
					background: $theme_background_medium;
					.label-cell {
						label {
							color: $theme_font;
						}
					}
				}
				&.is-active {
					.accordion-title {
						color: $theme_font;
						.sub-text {
							color: $theme_font_hover;
						}
						background: $theme_background_light;
					}
				}
			}
		}
	}
	.badge {
		@each $name, $color in $badge-palette {
			&.#{$name} {
				background: $color;
				color: color-pick-contrast($color, ($badge-color, $badge-color-alt));
			}
		}
	}
	.label {
		@each $name, $color in $label-palette {
			&.#{$name} {
				background: $color;
				color: color-pick-contrast($color, ($label-color, $label-color-alt));
			}
		}
	}
	.callout {
		background-color: $theme_font_light;
		color: $theme_alt_font;
		label {
			color: $theme_alt_font;
		}
		a {
			color: $theme_dark_link_color;
			&:hover {
				color: $theme_dark_link_hover;
			}
		}
	}
	.debug-panel {
		&.theme-good {
			@include callout-style($theme_good);
		}
		&.theme-caution {
			@include callout-style($theme_caution);
		}
	}
	.color-swatch {
		&.theme_bg_gray { background: $theme_background_gray; }
		&.theme_bg_light { background: $theme_background_light; }
		&.theme_bg { background: $theme_background; }
		&.theme_bg_medium { background: $theme_background_medium; }
		&.theme_bg_dark { background: $theme_background_dark; }
		&.theme_good { background: $theme_good; }
		&.theme_caution { background: $theme_caution; }
		&.theme_conference { background: $theme_conference; }
		&.theme_weekend { background: $theme_weekend; }
	}
	// Content
	header {
		.payweek-button,
		.payweek-label {
			color: $theme_font;
			&:hover {
				color: $theme_font_hover;
				& > span {
					color: $theme_font_hover;
				}
			}
			&:focus {
				background-color: transparent !important;
				& > span {
					color: $theme_font;
				}
			}
			& > span {
				color: $theme_font;				
			}
		}
		span.payweek-label {
			&:hover {
				color: $theme_font;
			}
		}
		.header-nav {
			background: $theme_good;
		}
	}
	section.payroll-content {
		hr {
			border-color: $theme_font;
		}
		h2 > small {
			color: $theme_font_hover;
		}
		.payWeek-table {
			thead th,
			tbody td .weekHeader {
				background: $theme_background;
				color: $theme_font;
			}
			tbody td {
				border-color: $theme_font;
				color: $theme_font;
				&.weekend {
					background: $theme_weekend;
					color: $weekend_background_font;
					&.today {
						.dateDetails {
							.dateNum {
								color: $theme_weekend;
								background: $weekend_background_font;
							}
						}
					}
				}
				&.filled {
					background: $theme_good;
					color: $good_background_font;
					&.today {
						.dateDetails {
							.dateNum {
								color: $theme_good;
								background: $good_background_font;
							}
						}
					}
				}
				&.prefilled {
					background: $theme_good_dark;
					color: $good_dark_background_font;
					&.today {
						.dateDetails {
							.dateNum {
								color: $theme_good_dark;
								background: $good_dark_background_font;
							}
						}
					}
				}
				&.pending {
					background: $theme_caution;
					color: $caution_background_font;
					&.today {
						.dateDetails {
							.dateNum {
								color: $theme_caution;
								background: $caution_background_font;
							}
						}
					}
				}
				&.conference {
					background: $theme_conference;
					color: $conference_background_font;
					&.today {
						.dateDetails {
							.dateNum {
								color: $theme_conference;
								background: $conference_background_font;
							}
						}
					}
				}
				&.highlight {
					background: $theme_highlight;
					color: $highlight_background_font;
					&.today {
						.dateDetails {
							.dateNum {
								color: $theme_highlight;
								background: $highlight_background_font;
							}
						}
					}
				}
			}
			&.summary {
				thead {
					tr {
						th.highlight {
							background: $theme_highlight;
							color: $highlight_background_font;
						}
					}
				}
			}
		}
		.weekSummary,
		.timeoffSummary {
			.weekTotals {
				border-color: $theme_font;
				&.weekend {
					background: $theme_weekend;
					color: $weekend_background_font;
					hr {
						border-color: $weekend_background_font;
					}
				}
				&.filled {
					background: $theme_good;
					color: $good_background_font;
					hr {
						border-color: $good_background_font;
					}
					dd.pending-line {
						background: $theme_caution;
						color: $caution_background_font;
					}
				}
				&.pending {
					background: $theme_caution;
					color: $caution_background_font;
					hr {
						border-color: $caution_background_font;
					}
				}
				&.conference {
					background: $theme_conference;
					color: $conference_background_font;
					hr {
						border-color: $conference_background_font;
					}
				}
			}
		}
		.pto-totals {
			div.pto-personal {
				background: $theme_good;
				color: $good_background_font;
				div.current-requests {
					border-color: $good_background_font;
					background: $theme_background_fade;
					.checkbox-switch-container {
						border-color: $theme_good;
					}
				}
			}
		}
		.time-entry,
		.result-entry,
		.expense-entry {
			background: $theme_background_gray;
			color: $theme_background_gray_font;
			span.hint-text {
				color: $theme_background_gray_font;
			}
			.contract-block > .title {
				background: $theme_good_dark;
				color: $good_dark_background_font;
			}
			.contract-table {
				tr.table-break {
					background: $theme_background_gray;
					& > td {
						background: $theme_background_gray;
						border-top: 3px solid $good_background_font;
						border-bottom: 3px solid $good_background_font;
					}
				}
			}
			&.offset {
				background: $theme_good_faded;
				border: 1px solid $theme_background_dark;
				color: $theme_good_faded_font;
				span.hint-text {
					color: $theme_good_faded_font;
				}
				.contract-table {
					tr.table-break {
						background: $theme_good_faded;
						& > td {
							background: $theme_good_faded;
						}
					}
				}
			}
		}
		.tabs {
			& > .tabs-title {
				& > a {
					background: $theme_good;
					color: $good_background_font;
					border-color: $good_background_font;
					&:hover {
						background: $theme_good_faded;
						color: $theme_good_faded_font;
					}
				}
				&.is-active > a {
					background: $theme_background_medium;
					color: $good_background_font;
					border-bottom: 1px solid $theme_background_medium;
					&:hover {
						background: $theme_background_medium;
					}
				}
			}
		}
		.tabs-content {
			background: $theme_background_medium;
			color: $theme_font;
			border-color: $theme_font;
			a {
				color: $theme_link_color;
				&:hover {
					color: $theme_link_hover;
				}
			}
			dl {
				dd {
					border-color: $theme_font;
				}
			}
			.accordion {
				& > .accordion-item {
					.accordion-title {
						background: $theme_good_faded;
						color: $good_background_font;
						border: 2px solid $good_background_font;
						&:hover {
							background: $theme_good_light;
							color: $theme_font;
						}
					}
					.accordion-content {
						color: $theme_font_light;
						table {
							th {
								background: $theme_good_faded;
								color: $good_background_font;
								border-color: $good_background_font;
							}
						}
					}
					&.is-active {
						.accordion-title {
							background: $theme_good;
							color: $good_background_font;
							&:hover {
								background: $theme_good;
							}
						}
					}
				}
			}
		}
	}
}